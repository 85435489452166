import { isMobile } from 'react-device-detect';
export interface segmentProperties {
  productTitle?: string;
  pageTitle: string;
  object: string;
  pageUrl?: string;
  successFlag?: boolean;
  resultValue: string;
  CTA: string;
  location: string;
  action: string;
  field: string;
}

/**
 *
 */
export const segmentsTracking = (
  eventName: string,
  {
    productTitle,
    pageTitle,
    object,
    pageUrl,
    successFlag,
    resultValue,
    CTA,
    location,
    action,
    field,
  }: segmentProperties
) => {
  let device = '';
  if (isMobile) {
    console.log(`is mobile`, device);
  }
  window?.bluemixAnalytics?.trackEvent(eventName, {
    productTitle,
    pageTitle,
    pageUrl,
    object: object,
    successFlag,
    resultValue,
    CTA,
    location,
    action,
    field,
  });
};
