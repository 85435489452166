import React, { useState } from "react";
import { ThanksModal, thanksTable } from "../../common/ThanksModal";
import { useTranslation } from "react-i18next";
import { productTitle } from "../../lib/tracker";

const pageTitle = "IBM Turbonomic Demo";

export interface thanksProps {
  bpName: string;
  demo: string;
}

const Thanks = ({ bpName, demo }: thanksProps) => {
  const { t /* i18n */ } = useTranslation();
  const [showThanksModal, setShowThanksModal] = useState(true);

  const titleThanks: thanksTable[] = [
    {
      group: "title",
      value: !demo ? t("thankyou.Title1") : t("thankyou.Title2"),
    },
  ];

  const widgetTitleThanks: thanksTable[] = [
    {
      group: "widgetTitle1",
      value: "",
    },
  ];
  const contentThanks: thanksTable[] = [];
  bpName &&
    contentThanks.push({
      group: String(t("messages.preferred_bp")),
      value: bpName,
    });

  const buttonThanks: thanksTable[] = [
    {
      group: "button1",
      value: String(t("thankyou.Button")),
    },
  ];

  const demoContent = {
    demo,
    demoText: String(t("thankyou.demoText")),
  };

  return (
    <div>
      <ThanksModal
        showThanksModal={showThanksModal}
        pageTitle={pageTitle}
        object="SA Page"
        productTitle={productTitle}
        pageUrl={""}
        location={"Demo thank you page"}
        titleThanks={titleThanks}
        widgetTitleThanks={widgetTitleThanks}
        contentThanks={contentThanks}
        buttonThanks={buttonThanks}
        setShowThanksModal={setShowThanksModal}
        link={demoContent}
      />
    </div>
  );
};

export default Thanks;
