import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { segmentTracking } from '../../lib/tracker';
import { Button } from 'carbon-components-react';
import { Restart32, ArrowRight16 } from '@carbon/icons-react';
/** Import figma listeners */
import { DSH_Figma_Utils } from '../../common/figmaListener';
import { productTitle } from '../../lib/tracker';
import { getEnv } from '../../common/useEnv';
import { readBucket } from 'src/common/readCOS';
import { LangDefaults } from 'src/common/useLang';
/** */
const pageTitle = 'IBM OpenPages Interactive Demo';

class AuditManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s1: true,
      text: 'click',
      iframeUrl: '',
      figmaKey: '',
      figmaToken: '',
    };
  }

  componentDidMount = () => {
    const track = 'track3';
    const localeCode = this.props.urxlang;
    console.log(` userLanguage`, this.props.urxlang, localeCode);
    getEnv().then((res) => {
      const bucket = res[1];
      this.setState({ figmaToken: res[2] });
      readBucket(bucket).then((response) => {
        console.log(` content`, response[0]);
        const figmaTrackContent = response[0][track];
        figmaTrackContent.map((obj) => {
          console.log(`Processing: `, obj.countryLang);
          if (obj.countryLang === localeCode) {
            console.log(`Found demo:`, obj.countryLang);
            this.setState({
              iframeUrl: obj.emailDemoUrl,
              figmaKey: obj.figmaKey,
            });
            return obj;
          }
          return '';
        });
        if (this.state.iframeUrl.length === 0) {
          figmaTrackContent.map((obj) => {
            console.log(`Processing default: `, LangDefaults.localCountryLang);
            if (obj.countryLang === LangDefaults.localCountryLang) {
              console.log(`Found demo:`, obj.countryLang);
              this.setState({
                iframeUrl: obj.emailDemoUrl,
                figmaKey: obj.figmaKey,
              });
              return obj;
            }
            return '';
          });
        }

        DSH_Figma_Utils.init({
          figma_fileKey: this.state.figmaKey,
          figma_accessToken: this.state.figmaToken,
          metricsTracking: segmentTracking,
          clickCallback: null,
          product_title: productTitle,
          page_title: pageTitle,
        });
      });
    });
  };

  render() {
    const { t, runningMobile /* i18n */ } = this.props;
    return (
      <div>
        <div className="bx--grid" id="content3-page">
          {/* <Content id="main-area">{t('MainPage.content')}</Content> */}
          <div className="bx--row b">
            <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-16">
              <div className="bx--row">
                <div className="bx--col-lg-3 bx--col-md-2 bx--col-sm-2">
                  <Button
                    className="button-header button-header2"
                    kind="tertiary"
                    href={'/'}
                    renderIcon={Restart32}
                    onClick={() => {
                      segmentTracking('CTA Clicked', {
                        pageTitle: pageTitle,
                        object: 'Home page',
                        resultValue: 'CTA clicked',
                        CTA: 'Back to Menu',
                        location: 'Demo',
                        action: 'Clicked',
                        field: '',
                      });
                    }}
                  >
                    {t('home.text')}
                  </Button>
                </div>
                <div className="bx--col-lg-4 bx--col-md-5 bx--col-sm-2">
                  <Button
                    kind="tertiary"
                    className="button-header marButton button-header2"
                    renderIcon={ArrowRight16}
                    href={'/component4'}
                    onClick={() => {
                      segmentTracking('CTA Clicked', {
                        pageTitle: pageTitle,
                        object: 'Start path 3 button',
                        resultValue: 'CTA clicked',
                        CTA: 'Next path: Administrator',
                        location: 'Demo',
                        action: 'Clicked',
                        field: '',
                      });
                    }}
                  >
                    {t('tile3_path_cta.text')}
                  </Button>
                </div>
              </div>
              <div className="bx--row">
                <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                  <div className="section">
                    {this.props.bpName ? (
                      <>
                        <div className="bx--row figma_top">
                          <div className="bx--col"></div>
                        </div>
                      </>
                    ) : (
                      <div className="bx--row"></div>
                    )}

                    <iframe
                      tabIndex={'-1'}
                      onLoad={() => {
                        window.parent.focus();
                      }}
                      className={
                        runningMobile ? 'figma_frame3_mobile' : 'figma_frame3'
                      }
                      title="openpages demo path 3"
                      id="figma_demo3"
                      src={this.state.iframeUrl}
                    ></iframe>
                    <div className="bx--row">
                      <div className="bx--col figma_bottom3"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(AuditManager);
