import {
  Header,
  HeaderName,
  HeaderNavigation,
  HeaderGlobalBar,
  SkipToContent,
} from 'carbon-components-react';
import { ArrowRight16 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { segmentTracking } from '../../lib/tracker';
import { Button, Loading } from 'carbon-components-react';
import { useCookiesURL } from 'src/lib/UseCookiesUrl';
import Cookies from 'js-cookie';
import * as URX_FORM from '../../constants/urx-form';
import { initUrxForm } from 'src/common/loadUrxForm';
import { useEnv } from '../../common/useEnv';
import { useState } from 'react';
import Thanks from '../Thanks/Thanks';
import { LangDefaults } from 'src/common/useLang';
import { urxScriptLoader, urxScript } from 'src/common/globalDefinitions';
import { Helmet } from 'react-helmet';

const pageTitle = 'IBM OpenPages Interactive Demo';

const NavHeader = ({
  bpName,
  logo,
  companyId,
  desc,
  mssp,
  params,
  drawerOpen,
  setDrawerOpen,
  closeModal,
  setCloseModal,
  setDemo,
  demo
}) => {
  const { t /* i18n */ } = useTranslation();
  const cookieURL = useCookiesURL();
  const [environment] = useEnv();
  const [urxlang] = useState(LangDefaults.localCountryLang);
  const [loadIcon, setLoadIcon] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [roiLink, SetRoiLink] = useState('');

  window.onUrxFormSubmitSuccess = () => {
    setDrawerOpen(false);
    setCloseModal(true);
    setFormComplete(!formComplete);
  };

  const setCookie = (demo) => {
    const getBp = () => {
      if (bpName) return bpName;
      if (logo) return companyId;
      if (desc) return desc;
      if (mssp) return mssp;
      const bpField = document.querySelector('#Q_BPNAME');
      return bpField ? bpField.value : '';
    };
    const formId = demo ? URX_FORM.FORM_ID_DEMO : URX_FORM.FORM_ID_ROI
    const text = JSON.stringify({
      source: formId,
      // One field to provide all values (stringify)
      dcq: {
        Q_NOTESDATA:
          cookieURL +
          ' Book demo requested -IBM OpenPages Interactive Demo-' +
          ' | FormId:' +
          formId +
          (getBp() ? `|Preferred BP:${getBp()}` : ''),
        Q_ASSET_NAME: 'IBM OpenPages Interactive Demo', // ISC - Asset Name
      },
    });
    Cookies.set('urxdcq', text);
    console.log('cookie', Cookies.get('urxdcq'));
  };
  const getProduct = () => {
    let url = 'https://www.ibm.com/products/openpages'.concat(params);
    segmentTracking('CTA Clicked', {
      pageTitle: pageTitle,
      object: 'Nav Header',
      resultValue: `Loaded: ${url}`,
      CTA: 'Requested an URL',
      location: 'Demo',
      action: 'Clicked',
      field: '',
    });
    setTimeout(async () => {
      window.open(url);
    }, 250);
  };

  // const CalculateROI = () => {
  //   let url = "https://tei.forrester.com/go/ibm/openpagesdynamic/index.html?lang=en-us";

  //   segmentTracking("CTA Clicked", {
  //     pageTitle: pageTitle,
  //     object: "Nav Header",
  //     resultValue: `Loaded: ${url}`,
  //     CTA: "Calculate your ROI",
  //     location: "Demo",
  //     action: "Clicked",
  //     field: "",
  //   });
  //   setTimeout(async () => {
  //     setCookie();
  //     window.open(url);
  //   }, 250);
  // };

  return (
    <div className="Header">
      <Helmet>
        <script type="application/javascript" src={urxScriptLoader}></script>
        <script type="application/javascript" src={urxScript}></script>
      </Helmet>
      <Header aria-label="Client Center NavBar">
        <SkipToContent />
        <HeaderName
          element={Link}
          to={params}
          prefix="IBM"
          onClick={() => {
            getProduct();
          }}
        >
          <b>{t('Page.title')}</b>
        </HeaderName>
        <HeaderNavigation aria-label="App-Navbar">
          {/* <HeaderMenuItem element={Link} to={`/page-link`}>{t('NavHeader.8')}</HeaderMenuItem> */}
        </HeaderNavigation>
        <HeaderGlobalBar>
          <Button
            className="button-header button-header2 mar bx--btn bx--btn--tertiary"
            renderIcon={ArrowRight16}
            onClick={() => {
              // CalculateROI();
              setLoadIcon(true);
              initUrxForm(
                environment,
                URX_FORM.INSTANCE_ID,
                URX_FORM.FORM_ID_ROI,
                urxlang,
                companyId
              );
              SetRoiLink('https://tei.forrester.com/go/ibm/openpagesdynamic/index.html?lang=en-us');
              segmentTracking('CTA Clicked', {
                pageTitle: pageTitle,
                object: 'Nav Header',
                resultValue: 'CTA clicked',
                CTA: 'Calculate your ROI',
                location: 'Demo',
                action: 'Clicked',
                field: '',
              });
              setTimeout(
                async () => {
                  setDemo(false);
                  setCookie(false);
                  setLoadIcon(false);
                  setDrawerOpen(true);
                },
                bpName ? 1000 : 10
              );

            }}
          >
            {t('calculateRoi.title')}
          </Button>
          <Button
            className="button-header button-header2"
            renderIcon={ArrowRight16}
            kind="primary"
            onClick={() => {
              setLoadIcon(true);
              initUrxForm(
                environment,
                URX_FORM.INSTANCE_ID,
                URX_FORM.FORM_ID_DEMO,
                urxlang,
                companyId
              );
              segmentTracking('CTA Clicked', {
                pageTitle: pageTitle,
                object: 'Nav Header',
                resultValue: 'CTA clicked',
                CTA: 'Book a live demo',
                location: 'Demo',
                action: 'Clicked',
                field: '',
              });
              setTimeout(
                async () => {
                  setDemo(true);
                  setCookie(true);
                  setLoadIcon(false);
                  setDrawerOpen(true);
                },
                bpName ? 1000 : 10
              );
            }}
          >
            {t('bookDemo.title')}
          </Button>
        </HeaderGlobalBar>
      </Header>
      <div className="button-set">{loadIcon ? <Loading /> : null}</div>
      {formComplete && !demo && <Thanks bpName={bpName} demo={roiLink} />}
      {formComplete && demo && <Thanks bpName={bpName} />}
    </div>
  );
};

export default NavHeader;
