import { segmentProperties } from "./tracker";
import { environment, getVariable } from "./useEnv";

let urxScript = "";
let urxScriptLoader = "";

console.log(`Form Server: `, getVariable());
if (getVariable() === environment.stage) {
  urxScriptLoader =
    "https://wwwstage.ibm.com/account/ibmidutil/widget/js/loader.js";
  urxScript = "https://wwwstage.ibm.com/account/ibmidutil/widget/js/main.js";
} else {
  urxScript = "https://www.ibm.com/account/ibmidutil/widget/js/main.js";
  urxScriptLoader = "https://www.ibm.com/account/ibmidutil/widget/js/loader.js";
}
export type RenderFnWithOptions = (
  instanceId: string,
  formId: string,
  langCode: string,
  options: {
    column: number;
    theme: "light";
    singleStep: boolean;
    triggerManually: boolean;
  },
  cb: (e: HTMLElement) => boolean,
  bpid?: string
) => Promise<void>;

declare global {
  interface Window {
    // add custom properties and methods
    urxEnvironment: string;
    onUrxFormSubmitSuccess: () => void;
    renderUrxWidget: RenderFnWithOptions;
    digitalData: {
      page: {
        attributes: {
          bpid: string;
        };
      };
      user: {
        location: {
          country: string;
        };
      };
    };
    bluemixAnalytics: {
      trackEvent: (
        eventName: string,
        {
          pageTitle,
          object,
          resultValue,
          CTA,
          location,
          action,
          field,
        }: segmentProperties
      ) => void;
    };
    loadedUrxForm: boolean;
  }
}

export { urxScript, urxScriptLoader };
