import { useEffect, useState } from 'react';
import { executeNoBodyRequest } from '../common/fetchData';
/**
 * hook to get environment name
 * We can't use the staging setting unitil we know the MIP team enables
 * every single form used by the widgets from thh staging environment.
 * /*
 * @info: use
 * `$SME$ test`
 * for Company name to prevent creating official IBM CI
 *
 * @returns {string} environment
 */

export enum environment {
  production = 'production',
  stage = 'stage',
  preview = 'preview',
}

export interface variables {
  environment: string;
  bucket: string;
  figmaToken: string;
}

let vars = {} as variables;

let variable = environment.preview;
let bucket = '';

export const getEnv = async (): Promise<[string, string, string]> => {
  let env = environment.preview;
  let bucketFile = '';
  let token = '';
  const hostingService = '/environment';
  await executeNoBodyRequest(`${hostingService}`, 'GET').then((response) => {
    //console.log(`Obtained: `, response);
    if (response.status === 200) {
      vars = response.message as unknown as variables;
      env = vars.bucket as environment;
      bucketFile = vars.bucket;
      token = vars.figmaToken;
      if (vars.environment === 'production') {
        env = environment.production;
        variable = environment.production;
      } else if (vars.environment === 'stage') {
        env = environment.stage;
        variable = environment.stage;
      }
    }
    return [env, bucketFile, token];
  });
  return [env, bucketFile, token];
};

export const getVariable = () => {
  return variable;
};

export const getBucket = () => {
  return bucket;
};

export const useEnv = (): [string] => {
  const [env, setEnv] = useState(environment.preview);
  useEffect(() => {
    const hostingService = '/environment';
    executeNoBodyRequest(`${hostingService}`, 'GET').then((emailResponse) => {
      console.log(`Obtained `, emailResponse);
      if (emailResponse.status === 200) {
        const envVar = emailResponse.message as unknown as variables;
        console.log(`Environment:`, envVar.environment);
        if (envVar.environment === 'production') {
          setEnv(environment.production);
          variable = environment.production;
        } else if (envVar.environment === 'stage') {
          setEnv(environment.stage);
          variable = environment.stage;
        }
      }
      return env;
    });
  }, [env]);
  return [env];
};

