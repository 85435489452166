import './App.scss';
import { useEffect, useState } from 'react';
import { Content } from 'carbon-components-react/es/components/UIShell';
import NavHeader from './components/NavHeader';
import { Route, Switch } from 'react-router-dom';
import MainPage from './content/MainPage';
import { createContext } from 'react';
import { useBPName } from 'src/common/useBPName';
import { UseLang } from 'src/common/useLang';
import { UT3WATXZ } from 'src/constants/defaults'; // 5000
import BusinessPartner from 'src/components/BusinessPartner/BusinessPartner';
import countryList from 'src/data/currencies.json';
import Component4 from 'src/content/Administrator';
import Component3 from 'src/content/AuditManager';
import Component2 from 'src/content/RegulatoryComplianceManager';
import Component1 from 'src/content/OperationalRiskAnalyst';
import FormModal from './components/UrxForm/FormModal';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

export const TormContext = createContext(null);

function App() {
  const { bpName, logo, companyId, desc, mssp, params } = useBPName(
    UT3WATXZ,
    true
  );
  const [, setCurrency] = useState('');
  const [, setCountry] = useState('');
  const countryToTest = 'us';
  const test = false;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [demo, setDemo] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const { t /* i18n */ } = useTranslation();
  const [runningMobile] = useState(isMobile);
  const [lang, userLocale, country, currency] = UseLang(
    countryList,
    test,
    countryToTest,
    setCurrency,
    setCountry
  );
  const [urxlang, setURXlang] = useState(lang);

  useEffect(() => {
    console.log(`Running mobile: `, runningMobile);

    setCurrency(currency);
    setCountry(country);
    console.log(
      '\n*****came APP to this',
      lang,
      userLocale,
      currency,
      country,
      urxlang
    );
  }, [
    runningMobile,
    test,
    setURXlang,
    urxlang,
    currency,
    country,
    lang,
    userLocale,
  ]);

  return (
    <div className="App">
      <div className={runningMobile ? 'message-mobile' : 'demo-mobile'}>
        <p className="type2">{t('landing_text.orentation')}</p>
      </div>
      <div className={runningMobile ? 'demo-mobile' : 'demo-laptop'}>
        <div>
          <NavHeader
            bpName={bpName}
            logo={logo}
            companyId={companyId}
            desc={desc}
            mssp={mssp}
            params={params}
            lang={lang}
            urxlang={urxlang}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            closeModal={closeModal}
            setCloseModal={setCloseModal}
            setDemo={setDemo}
            demo={demo}
          />
          {bpName ? (
            <>
              <BusinessPartner bpName={bpName} logo={logo} desc={desc} />
            </>
          ) : null}
          <Content className="app-wrapper">
            <Switch>
              <Route exact path="/">
                <MainPage
                  bpName={bpName}
                  urxlang={urxlang}
                  logo={logo}
                  companyId={companyId}
                  desc={desc}
                  mssp={mssp}
                  params={params}
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                  closeModal={closeModal}
                  setCloseModal={setCloseModal}
                  showImage={showImage}
                  setShowImage={setShowImage}
                  demo={demo}
                  setDemo={setDemo}
                  runningMobile={runningMobile}
                />
              </Route>
              <Route exact path="/component1">
                <Component1
                  bpName={bpName}
                  runningMobile={runningMobile}
                  urxlang={urxlang}
                  logo={logo}
                  companyId={companyId}
                  desc={desc}
                  mssp={mssp}
                  params={params}
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                  closeModal={closeModal}
                  setCloseModal={setCloseModal}
                  showImage={showImage}
                  setShowImage={setShowImage}
                  demo={demo}
                  setDemo={setDemo}
                />
              </Route>
              <Route exact path="/component2">
                <Component2
                  bpName={bpName}
                  runningMobile={runningMobile}
                  urxlang={urxlang}
                  logo={logo}
                  companyId={companyId}
                  desc={desc}
                  mssp={mssp}
                  params={params}
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                  closeModal={closeModal}
                  setCloseModal={setCloseModal}
                  showImage={showImage}
                  setShowImage={setShowImage}
                  demo={demo}
                  setDemo={setDemo}
                />
              </Route>
              <Route exact path="/component3">
                <Component3
                  bpName={bpName}
                  runningMobile={runningMobile}
                  urxlang={urxlang}
                  logo={logo}
                  companyId={companyId}
                  desc={desc}
                  mssp={mssp}
                  params={params}
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                  closeModal={closeModal}
                  setCloseModal={setCloseModal}
                  showImage={showImage}
                  setShowImage={setShowImage}
                  demo={demo}
                  setDemo={setDemo}
                />
              </Route>
              <Route exact path="/component4">
                <Component4
                  bpName={bpName}
                  runningMobile={runningMobile}
                  urxlang={urxlang}
                  logo={logo}
                  companyId={companyId}
                  desc={desc}
                  mssp={mssp}
                  params={params}
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                  closeModal={closeModal}
                  setCloseModal={setCloseModal}
                  showImage={showImage}
                  setShowImage={setShowImage}
                  demo={demo}
                  setDemo={setDemo}
                />
              </Route>
              {/* Routes with language selection */}
              <Route exact path="/:lng">
                <MainPage
                  paramArray={[bpName, logo, companyId, desc, mssp, params]}
                />
              </Route>
              <Route exact path="/:lng/component1">
                <Component1
                  bpName={bpName}
                  runningMobile={runningMobile}
                  urxlang={urxlang}
                  logo={logo}
                  companyId={companyId}
                  desc={desc}
                  mssp={mssp}
                  params={params}
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                  closeModal={closeModal}
                  setCloseModal={setCloseModal}
                  showImage={showImage}
                  setShowImage={setShowImage}
                  demo={demo}
                  setDemo={setDemo}
                />
              </Route>
              <Route exact path="/:lng/component2">
                <Component2
                  bpName={bpName}
                  runningMobile={runningMobile}
                  urxlang={urxlang}
                  logo={logo}
                  companyId={companyId}
                  desc={desc}
                  mssp={mssp}
                  params={params}
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                  closeModal={closeModal}
                  setCloseModal={setCloseModal}
                  showImage={showImage}
                  setShowImage={setShowImage}
                  demo={demo}
                  setDemo={setDemo}
                />
              </Route>
              <Route exact path="/:lng/component3">
                <Component3
                  bpName={bpName}
                  runningMobile={runningMobile}
                  urxlang={urxlang}
                  logo={logo}
                  companyId={companyId}
                  desc={desc}
                  mssp={mssp}
                  params={params}
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                  closeModal={closeModal}
                  setCloseModal={setCloseModal}
                  showImage={showImage}
                  setShowImage={setShowImage}
                  demo={demo}
                  setDemo={setDemo}
                />
              </Route>
              <Route exact path="/:lng/component4">
                <Component4
                  bpName={bpName}
                  runningMobile={runningMobile}
                  urxlang={urxlang}
                  logo={logo}
                  companyId={companyId}
                  desc={desc}
                  mssp={mssp}
                  params={params}
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                  closeModal={closeModal}
                  setCloseModal={setCloseModal}
                  showImage={showImage}
                  setShowImage={setShowImage}
                  demo={demo}
                  setDemo={setDemo}
                />
              </Route>
            </Switch>
            <FormModal
              bpName={bpName}
              logo={logo}
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
              closeModal={closeModal}
              setCloseModal={setCloseModal}
              demo={demo}
            ></FormModal>
          </Content>
        </div>
      </div>
    </div>
  );
}

export default App;
