import { useTranslation } from 'react-i18next';
import { segmentTracking } from '../../lib/tracker';
import { ClickableTile, Button } from 'carbon-components-react';
import Helmet from 'react-helmet';
import { urxScriptLoader, urxScript } from 'src/common/globalDefinitions';
import picture from '../../assets/Open_pages.svg';

const pageTitle = 'IBM OpenPages Interactive Demo';

export default function MainPage({
  bpName,
  logo,
  companyId,
  desc,
  mssp,
  params,
}) {
  const { t /* i18n */ } = useTranslation();

  return (
    <div>
      <Helmet>
        <script type="application/javascript" src={urxScriptLoader}></script>
        <script type="application/javascript" src={urxScript}></script>
      </Helmet>
      <div className="bx--grid imgBack" id="main-page">
        <div className="section">
          {bpName ? (
            <>
              <div className="bx--row">
                <div className="bx--col figma_top"></div>
              </div>
            </>
          ) : null}
          <div className="bx--row mar1"></div>
          <div className="bx--row">
            {/* <div className="bx--col-lg-1"></div> */}
            <div className="bx--col-lg-10 bx--col-md-7 bx--col-sm-3">
              <div className="bx--row">
                <div className="bx--col-lg-14 bx--col-md-7 bx--col-sm-3">
                  <p className="type1">{t('landing_title.text')}</p>
                </div>
              </div>
              <div className="bx--row mar2">
                <div className="bx--col-lg-12 bx--col-md-7 bx--col-sm-3">
                  <p className="type8">{t('landing_subtitle_italic.text')}</p>
                </div>
              </div>
              <div className="bx--row mar2">
                <div className="bx--col-lg-16 bx--col-md-7 bx--col-sm-3">
                  <p className="type2">{t('landing_subtitle.text')}</p>
                </div>
              </div>
              <div className="bx--row mar2">
                <div className="bx--col-lg-12 bx--col-md-7 bx--col-sm-3">
                  <p className="type2">{t('landing_text.text')}</p>
                </div>
              </div>
            </div>
            <div className="bx--col-lg-6 bx--col-md-3 bx--col-sm-3 mar5">
              <div className="bx--row">
                <img src={picture} alt="Not found" id="img1"></img>
              </div>
            </div>
          </div>
          <div className="bx--row mar3">
            {/* <div className="bx--col-lg-16 bx--col-md-7 bx--col-sm-3">
              <div className="bx--row"> */}
            <div className="bx--col-lg-4 bx--col-md-3 bx--col-sm-2">
              <ClickableTile
                id="clickable-tile-1"
                className="tileHeight tile-position"
                href={'/component1'}
              >
                <p className="type6">{t('landing_tile1.title1')}</p>
                <p className="type5">{t('landing_tile1.title2')}</p>
                <br />
                <br />
                <p className="type4 mar8">{t('landing_tile1.text')}</p>
                <br />
                <br />
                <Button
                  kind="primary"
                  className="button-position"
                  style={{ minHeight: '30px', maxWidth: '156px' }}
                  onClick={() => {
                    segmentTracking('CTA Clicked', {
                      pageTitle,
                      object: 'Demo1 button',
                      resultValue: 'Demo1 button click',
                      CTA: 'Start',
                      location: 'Demo',
                      action: 'Demo1 button clicked',
                      field: '',
                    });
                  }}
                >
                  {t('tile_button.text')}
                </Button>
              </ClickableTile>
            </div>
            <div className="bx--col-lg-4 bx--col-md-3 bx--col-sm-2">
              <ClickableTile
                id="clickable-tile-2"
                className="tileHeight tile-position"
                href={'/component2'}
              >
                <p className="type6">{t('landing_tile2.title1')}</p>
                <p className="type5">{t('landing_tile2.title2')}</p>
                <br />
                <br />
                <p className="type4">{t('landing_tile2.text')}</p>
                <br />
                <br />
                <Button
                  kind="primary"
                  className="button-position"
                  style={{ minHeight: '30px', maxWidth: '156px' }}
                  onClick={() => {
                    segmentTracking('CTA Clicked', {
                      pageTitle,
                      object: 'Demo2 button',
                      resultValue: 'Demo2 button click',
                      CTA: 'Start',
                      location: 'Demo',
                      action: 'Demo2 button clicked',
                      field: '',
                    });
                  }}
                >
                  {t('tile_button.text')}
                </Button>
              </ClickableTile>
            </div>
            {/* <div className="bx--row mar2"> */}
            <div className="bx--col-lg-4 bx--col-md-3 bx--col-sm-2">
              <ClickableTile
                id="clickable-tile-3"
                className="tileHeight tile-position"
                href={'/component3'}
              >
                <p className="type6">{t('landing_tile3.title1')}</p>
                <p className="type5">{t('landing_tile3.title2')}</p>
                <br />
                <br />
                <p className="type4 mar8">{t('landing_tile3.text')}</p>
                <br />
                <br />
                <Button
                  kind="primary"
                  className="button-position"
                  style={{ minHeight: '30px', maxWidth: '156px' }}
                  onClick={() => {
                    segmentTracking('CTA Clicked', {
                      pageTitle,
                      object: 'Demo3 button',
                      resultValue: 'Demo3 button click',
                      CTA: 'Start',
                      location: 'Demo',
                      action: 'Demo3 button clicked',
                      field: '',
                    });
                  }}
                >
                  {t('tile_button.text')}
                </Button>
              </ClickableTile>
            </div>
            <div className="bx--col-lg-4 bx--col-md-3 bx--col-sm-2">
              <ClickableTile
                id="clickable-tile-3"
                className="tileHeight tile-position"
                href={'/component4'}
              >
                <p className="type6">{t('landing_tile4.title1')}</p>
                <p className="type5">{t('landing_tile4.title2')}</p>
                <br />
                <br />
                <p className="type4 mar8">{t('landing_tile4.text')}</p>
                <br />
                <br />
                <Button
                  kind="primary"
                  className="button-position"
                  style={{ minHeight: '30px', maxWidth: '156px' }}
                  onClick={() => {
                    segmentTracking('CTA Clicked', {
                      pageTitle,
                      object: 'Demo4 button',
                      resultValue: 'Demo4 button click',
                      CTA: 'Start',
                      location: 'Demo',
                      action: 'Demo4 button clicked',
                      field: '',
                    });
                  }}
                >
                  {t('tile_button.text')}
                </Button>
              </ClickableTile>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
