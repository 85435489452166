import { segmentProperties, segmentsTracking } from '../common/tracker';
export const productTitle = 'IBM OpenPages Interactive Demo';
export const segmentTracking = (
  eventName: string,
  {
    pageTitle,
    object,
    resultValue,
    CTA,
    location,
    action,
    field,
  }: segmentProperties
) => {
  // Define variables specific for the tracking this project
  const pageUrl = window.location.pathname;
  //
  segmentsTracking(eventName, {
    productTitle,
    pageTitle,
    pageUrl,
    object,
    successFlag: true,
    resultValue,
    CTA,
    location,
    action,
    field,
  });
};
